<template>
  <main-layout>
    <div class="d-flex align-items-center">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="m-0 ms-3">{{ $t("appointmentOverview") }}</h1>
    </div>
    <div class="appointmentOverviewPage__wrapper">
      <div class="d-flex align-items-center">
        <avatar
          user-role="employees"
          :user-code="appointment.employee_code"
          :image-name="appointment.employee_image"
          size="100"
          width="100px"
          height="100px"
        />
        <div class="ml-20">
          <div class="appointmentOverviewPage__name">
            {{
              `${appointment.employee_first_name} ${appointment.employee_last_name}`
            }}
          </div>
          <div class="appointmentOverviewPage__code">
            {{ appointment.employee_code }}
          </div>
        </div>
      </div>
      <div class="row appointmentOverviewPage__rowWithStroke">
        <div class="col-lg-6">
          <div class="appointmentOverviewPage__title">
            {{ $t("companyCoverage") }}
          </div>
          <div class="appointmentOverviewPage__coast">
            €{{ appointment.company_coverage }}
          </div>
        </div>
        <div class="col-lg-6">
          <div class="appointmentOverviewPage__title">
            {{ $t("employeeCoast") }}
          </div>
          <div class="appointmentOverviewPage__coast">
            €{{ appointment.consultation_price }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="appointmentOverviewPage__title">
            {{ $t("employeeCalendarService") }}
          </div>
          <div class="appointmentOverviewPage__text mb-30">
            {{ appointment.service_name }}
          </div>
        </div>
        <div class="col-lg-6">
          <div class="appointmentOverviewPage__title">
            {{ $t("filterByExpert") }}
          </div>
          <div class="appointmentOverviewPage__text mb-30">
            <short-name
              :name="`${appointment.expert_first_name} ${appointment.expert_last_name}`"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="appointmentOverviewPage__title">{{ $t("Product") }}</div>
          <div class="appointmentOverviewPage__text">
            {{ appointment.consultation_name }}
          </div>
          <div class="appointmentOverviewPage__text">
            {{ $t("duration") }}
            <duration-time :data="appointment.consultation_duration" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="appointmentOverviewPage__title">
            {{ $t("appointmentDate") }}
          </div>
          <div class="appointmentOverviewPage__text">
            <data-formatted date-format="HH:mm" :data="appointment.time" />
          </div>
          <div class="appointmentOverviewPage__text">
            <data-formatted
              date-format="dd MMM yyyy"
              :data="appointment.time"
            />
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import Avatar from "@/components/UI/images/Avatar";
import ShortName from "@/components/components-helpers/ShortName";
import DurationTime from "@/components/components-helpers/DurationTime";
import DataFormatted from "@/components/components-helpers/DataFormatted";

export default {
  name: "AppointmentOverviewPage",
  components: {
    DataFormatted,
    DurationTime,
    ShortName,
    Avatar,
    HorizontalArrow,
    BaseButton,
    MainLayout,
  },
  data() {
    return {
      appointment: {},
    };
  },
  mounted() {
    this.getAppointment();
  },
  methods: {
    getAppointment() {
      this.$http
        .get(`/appointments/code/${this.$route.params.appointment}`)
        .then(({ data }) => {
          this.appointment = data.body;
        });
    },
  },
};
</script>

<style></style>
